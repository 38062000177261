import React from "react"
import { Layout } from "../components/Layout"

const equipo = props => {
  return (
    <Layout Jumbo={false}>
      <div className="legalContainer">
        <h2>Aviso Legal</h2>
        <p>
          Los derechos de propiedad industrial e intelectual de la web
          www.semte.es, su código fuente, diseño, estructura de navegación,
          bases de datos y los distintos elementos en él contenidos son
          titularidad de Semte Instalaciones SL, a quien corresponde el
          ejercicio exclusivo de los derechos de explotación de los mismos en
          cualquier forma, y en especial los derechos de reproducción,
          distribución, comunicación pública, puesta a disposición y
          transformación.
          <br />
          <br /> Respecto las reseñas de servicios de terceros que pudieran
          aparecer en la web, Semte Instalaciones SL, reconoce a favor de sus
          titulares los correspondientes derechos de propiedad industrial e
          intelectual, no implicando su sola mención o aparición en la web la
          existencia de derechos sobre las mismas. <br />
          <br />
          La utilización no autorizada de la información contenida en esta web,
          su reventa así como la lesión de los derechos de propiedad intelectual
          o industrial de Semte Instalaciones S.L. darán lugar a las
          responsabilidades legalmente establecidas. Semte Instalaciones, S.L.
          se reserva el derecho a actualizar, modificar o eliminar la
          información contenida en su web, además de su configuración,
          prestaciones o condiciones de acceso y uso en cualquier momento y sin
          necesidad de previo aviso. <br />
          <br />
          Semte Instalaciones, S.L. no garantiza la inexistencia de
          interrupciones o errores en el acceso a la web, ni asumirá
          responsabilidad alguna por problemas técnicos o fallos que se
          produzcan durante la conexión a Internet. Semte Instalaciones, S.L.
          tampoco garantiza la ausencia de virus o de otros elementos que puedan
          producir alteraciones en su sistema informático (software y hardware)
          o en los documentos electrónicos y ficheros almacenados en su sistema
          informático. Así pues, excluye cualquier responsabilidad por los daños
          y perjuicios que puedan producirse en el sistema informático, los
          documentos electrónicos o los ficheros de los usuarios. La prestación
          del servicio del sitio web y de los demás servicios tiene en principio
          una duración indefinida. Semte Instalaciones, S.L., no obstante, está
          autorizada para dar por terminada o suspender la prestación del
          servicio en cualquier momento. Queda estrictamente prohibido
          modificar, transmitir, distribuir, reutilizar, reenviar o usar la
          totalidad o parte del contenido de este sitio web (textos, imágenes,
          audio y/o vídeo) para propósitos públicos o comerciales sin la
          autorización escrita de Semte Instalaciones, S.L. <br />
          <br />
          Todo usuario o tercero que desee incluir dentro de su página web
          cualquier tipo de link o hipervínculo que conecte con la página web de
          Semte Instalaciones, S.L. o con las páginas que lo componen, habrá de
          notificarlo previamente a Semte Instalaciones, S.L., quien deberá dar
          su conformidad expresa a dicho link o hipervínculo.
        </p>
      </div>
    </Layout>
  )
}

export default equipo
